





























































































































































































































import Vue from 'vue';
import TVCommentButton from '@/components/Presenter/TVCommentButton.vue';
import { mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';

export default Vue.extend({
  components: { TVCommentButton, draggable },
  data() {
    return {
      page: 0,
      drag: false,
      sortedComments: [],
    };
  },
  async mounted() {
    await this.fetchData();
    this.loadMore();
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      comments: 'presenter/getPollComments',
      loading: 'presenter/getLoadingState',
      userTheme: 'auth/getUserTheme',
      pollById: 'presenter/getPollById',
      tvData: 'presenter/getTVData',
      selectedComments: 'presenter/getSelectedPollComments',
    }),
    pollTitle(): string {
      return this.pollById(this.$route.params.pollId).title;
    },
    selectedCommentContents(): string[] {
      return this.selectedComments.map((c: any) => c.text);
    },
    pollId(): string {
      return this.$route.params.pollId;
    },
    computedSelectedComments: {
      get(): any[] {
        if (this.sortedComments.length > 0) {
          return this.sortedComments;
        }
        return this.selectedComments;
      },
      set(value: any): void {
        this.sortedComments = value;
        this.$store.dispatch('presenter/updateSelectedCommentsOrder', {
          sortedComments: this.sortedComments,
          pollId: this.pollId,
        });
        setTimeout(() => {
          this.sortedComments = [];
        }, 500);
      },
    },
  },
  methods: {
    ...mapMutations({
      resetComments: 'presenter/RESET_POLL_COMMENTS',
    }),
    fetchData(): void {
      const presenterSessionId = this.$route.params.presenterSessionId;
      const pollId = this.$route.params.pollId;

      this.$store.dispatch('presenter/fetchPollComments', {
        shareToken: presenterSessionId,
        pollId,
        page: this.page,
      });
      this.$store.dispatch('presenter/bindSelectedPollComments', {
        sessionId: presenterSessionId,
        pollId,
      });
      this.$store.dispatch('presenter/bindPollTVData', {
        sessionId: presenterSessionId,
        pollId,
      });
    },
    showCopySuccessToast(): void {
      Vue.$toast.open({
        message: 'Kommentar erfolgreich kopiert.',
        type: 'success',
        position: 'top-right',
      });
    },
    async loadMore() {
      window.onscroll = () => {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.page += 1;
          this.fetchData();
        }
      };
    },
    addCommentToSelection(comment: any): void {
      this.$store.dispatch('presenter/addCommentToSelection', {
        comment,
        pollId: this.$route.params.pollId,
      });
    },
    removeCommentFromSelection(commentId: string): void {
      this.$store.dispatch('presenter/removeCommentFromSelection', {
        commentId,
        pollId: this.$route.params.pollId,
      });
    },
  },
  beforeDestroy(): void {
    this.resetComments();
  },
});
