




















import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  props: ['comment', 'pollId'],
  computed: {
    ...mapGetters({
      pollTVData: 'presenter/getPollTVData',
    }),
  },
  methods: {
    activateTVMode() {
      this.$store.dispatch('presenter/activateCommentTVMode', {
        state: !(
          this.pollTVData &&
          this.pollTVData.comment &&
          this.pollTVData.comment.text === this.comment.text
        ),
        comment: this.comment,
        pollId: this.pollId,
      });
    },
  },
});
